html {
  background-color: black;
}

body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

.font-family{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.label-bold {
  font-weight: bold;
}

.label-white {
  color: white;
  margin-right: 3px;
}

.background-black {
  background-color: black;
}

.table-inline {
  display: inline-block;
}

.grant-container {
  margin-top: 5%;
  margin-bottom: 5%;
}

/* .button {
  color: white;
  background-color: #b20837;
  margin: 5px;
  border-color: #b20837;
  border-radius: 15px;
  box-shadow: 0 5px #999;
}

.button:active {
  background-color: #8d0b2f;
  transform: translateY(4px);
  box-shadow: 0 3px #666;
}

.card-header {
  color: white;
  background-color: #b20837;
  font-family: Georgia, serif !important;
  width: auto;
}

.card-footer {
  border-radius: 0px 0px 15px 15px;
}

.card {
  width: 20rem;
  border-radius: 0px 0px 15px 15px;
  margin-left: 70px;
  margin-top: 15px;
  margin-bottom: 30px;
  height: auto;
}

.global-input {
  margin-bottom: 7px;
}

.global-input:focus {
  outline: none;
  border: 3px solid #b20837;
  border-radius: 5px 5px 5px 5px;
}
.label {
  font-weight: bold;
  margin-right: 3px;
}

.nav-link {
  color: white;
  font-size: 1.5em;
  font-family: "Times New Roman", serif;
}

.nav-link-active {
  background-color: white;
  color: white !important;
  font-weight: 700;
}

.navbar .navbar-nav .nav-link {
  color: #fff;
  font-size: 200px;
}

.navbar .navbar-nav .nav-link:hover {
  background-color: #ffacb7;
  color: #fff;
}

.nav-item:hover {
  color: white;
  font-weight: 700;
}

.nav-bar-container {
  position: sticky;
} */
