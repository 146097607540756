.grantList {
  /* display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left; */
  width: 100%;
  height: 100%;
  background-color: transparent;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
