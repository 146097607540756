#main-banner {
    background-color: black;
    text-transform: uppercase;
    color: #4fd706;
    border-radius: 32px;
    width: auto;
    height: auto;
    font-size: large;
    font-weight: 100px;
    text-align: center;
    padding: 10px;
    margin: 25px;
  }
  
.likeButton {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
   border-color:#000 !important;
  background-color:#000 !important;
  color:#000;
  border-radius: 15px;
}

.downloadButton {
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  width: 30px;
  border-color: white !important;
  background-color: white !important;
  color:white;
  border-radius: 15px;
}

.chartGraphButton {
  margin-right: 10px;
  margin-left: 10px;
  background-color: #7000ff;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  text-shadow: 0px 0px 4px #000;
}

.dateMessage {

  margin-left: 15px;
  
}

  .logo-grant-page {
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }
  .card-grant {
    margin: 15px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
    padding: 0% !important;
    border-radius: 20px !important;
    border-style: solid !important;
    border-color: #000000 !important;
    border-width: 1px !important;
  }
  
  .background-header {
    background-color: #000000 !important;
    padding: 0px !important;
    border-radius: 20px 20px 0px 0px !important;
  }
  .borderless {
    border: none !important;
    box-shadow: none !important;
    border-radius: 0px 0px 20px 20px !important;
  }
  
  .card-content {
    border: none !important;
    background-color: transparent !important;
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;
    margin-bottom: 0px;
  }
  
  .label {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .information {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .contact-button-col {
    text-align: middle;
  }
  
  .subscribe-button { 
    color:#000;
    border-color: #000;
  }
  /* .accordion-custom{
      background-color: grey !important;
     
  }
  
  .accordion-button {
      background-color: gray !important;
    }
    
    .accordion-button:focus {
      box-shadow: none;
    }
    
    .accordion-button:not(.collapsed) {
      color: #212529;
    }
  
  
  .accordion-item-custom {
      background-color: transparent !important;
  }
  
  .accordion-button:not(.collapsed) {
      color: #ffffff;
      background-color: #29008a;
      box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  } */
  .btn-subscribe {
    display: left;
    justify-content: center;
    align-items: center;
    padding: 0%;
    margin: 0%;
    background-color: transparent;
    border: none;
  }
  
  .button-Col {
    /* background: blue !important; */
    text-align: right;
    display: inline-block;
  }
  
  .banner-Col {
    background: transparent !important;
    display: inline-block;
  }
  
  .closing-icon-text {
    top: 0.35rem;
    position: absolute;
    color: white;
    font-size: 9px;
    text-transform: capitalize;
    font-weight: bold;
    rotate: 50deg;
  }
  
  .header-row {
    padding: 0% !important;
    margin: 0% !important;
  }
  
  .header-title {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #ffffff;
    padding: 10px;
  }
  
  .icon-unsubscribed {
    color: #363738;
    margin: 0%;
  }
  
  .icon-subscribed {
    color: #74f944;
    margin: 0%;
  }
  
  .closing-icon {
    color: #deff00;
    line-height: 50px;
  }
  
  .badge-new-icon {
    background-color: #7000ff !important;
    margin: 0%;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  /* .icon-new {
      background-color:#7000ff !important;
      margin: 0%;
      font-family: neue-haas-grotesk-display, sans-serif !important;
      font-weight: 700 !important;
      font-style: normal !important;
  
  } */
  
  /* .header-title{
      font-size: 1em;
      margin:auto;
      font-weight: 300px;
      text-transform: uppercase;
  }
  
  .btn-subscribe{
      display: flex ;
      justify-content: center;
      align-items: center;
      background: #7000ff;
      border-radius: 32px;
      border: 2px solid transparent;
      font-family: alternate-gothic-condensed-a ,sans-serif;
      letter-spacing: .02em;
      color: #fff ;
      transition: all .1s ease-out ;
      float: right ;
      box-shadow: none ;
      font-size: 13px ;
      font-weight: 600;
      height: 30px;
      width: 100px;   
  }
  
  .btn-subscribe:hover{
      background:#00ffd7 ;
      color: #000;
  }
  
  .header-background-override{
      background-color: transparent;
  } */
  .classification-span {
    background-color: #caf7ad;
  }
  
  .open-label {
    background-color: #a2ff0092;
    border-radius: 32px;
    border-color: #000000;
    border-width: 1px;
    border-style: solid;
    padding: 4px;
    margin: 5px;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .closed-label {
    background-color: #a6a8abac;
    border-radius: 32px;
    border-color: #000000;
    border-width: 1px;
    border-style: solid;
    padding: 4px;
    margin: 5px;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .open-span {
    background-color: #a2ff0092;
    border-radius: 32px;
    border-color: #000000;
    border-width: 1px;
    border-style: solid;
    padding: 4px;
    margin: 7px;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .closed-span {
    background-color: #a6a8abac;
    border-radius: 32px;
    border-color: #000000;
    border-width: 1px;
    border-style: solid;
    padding: 4px;
    margin: 5px;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .new-icon {
    color: #c500ff;
  }
  
  .label-date {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .label-technologies {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .grant-not-found {
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 500;
    font-style: normal;
    color: gray;
    font-size: 1.3rem;
  }
  .grantList {
    /* display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: left; */
    width: 100%;
    height: 100%;
    background-color: transparent;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .filter-category {
    border-radius: 0%;
    padding: 0%;
  }
  
  .filter-dropdown {
    padding: 25px;
  }
  
  .filter-card-header {
    text-align: center;
    background-color: #000000 !important;
    border-radius: 20px 20px 0px 0px !important;
  }
  
  .filter-card {
    width: auto !important;
    border-radius: 0% !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px !important;
    border-style: solid !important;
    border-color: #000000 !important;
    border-width: 1px !important;
    margin-left: 10%;
    margin-right: 10%;
  }
  
  /* <link rel="stylesheet" href="https://use.typekit.net/acw2slt.css">
  
  <style>
    @import url("https://use.typekit.net/acw2slt.css");
  </style> */
  .evolve-text-filter-header {
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 700;
    font-style: normal;
    color: white;
  }
  
  .evolve-text-filter-item {
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .evolve-text-filter-item-options {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .search-bar-container {
    border-radius: 0px 0px 20px 20px !important;
  }
  .contact-button {
    background-color: #7000ff !important;
    color: #ffffff;
    border-color: #7000ff !important;
    width: auto !important;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
  }

  .grant-page-button{
    background-color: #7000ff !important;
    color: #ffffff;
    border-color: #7000ff !important;
    width: auto !important;
    margin-right: auto;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
  }
  
  /* .contact-button:hover{
      background-color:   #00ffd7 !important;
      color: #000000 !important;
      border-color:   #00ffd7!important;
      width: auto !important;
      font-family: neue-haas-grotesk-display, sans-serif !important;
      font-weight: 500 !important;
      font-style: normal !important;
      
  } */
  .contact-modal-header {
    background-color: #000;
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 700;
    font-style: normal;
    color: white;
  }

  .specific-contact-button {
    background-color: #7000ff !important;
    color: white !important;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-size:x-small;
    /* font-style: normal !important; */
    border-color: transparent !important;
    margin-right: 10%;
  }
  
  .close-button {
    background-color: #000000 !important;
    color: white !important;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
    border-color: transparent !important;
  }
  .contact-modal-header {
    background-color: #000;
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 700;
    font-style: normal;
    color: white;
  }
  
  .send-button {
    background-color: #7000ff !important;
    color: white !important;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
    border-color: transparent !important;
    margin-right: 10%;
  }
  
  .close-button {
    background-color: #000000 !important;
    color: white !important;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
    border-color: transparent !important;
  }
  
  .generic-contact-button {
    font-style: normal !important;
    font-size: large !important;
    padding: 0.6rem !important;
    color: white !important;
    background-color: #7000ff !important;
    border-color: transparent !important;
    margin-bottom: 3rem;
    border-radius: 32px !important;
    letter-spacing: 0.02em !important;
    justify-content: center !important;
  }
  .create-form-button {
    background-color: #7000ff !important;
    color: white !important;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
    border: none !important;
  }
  .account-button {
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
    background-color: #7000ff !important;
    color: #ffffff;
    border-color: transparent !important;
  }
  
  .create-account-header {
    background-color: #000000;
    color: #ffffff;
    font-size: small;
    text-align: center;
    text-transform: uppercase;
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 500;
    font-style: normal;
    padding: 1rem;
  }
  
  .create-account-input {
    margin-top: 5px;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  .account-submit-button {
    background-color: #7000ff !important;
    color: #ffffff;
    border-color: #7000ff !important;
    width: 100px !important;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
    margin-top: 20px;
  }
  
  .account-close-button {
    background-color: #a6a8ab !important;
    color: #000000;
    border-color: #a6a8ab !important;
    width: 100px !important;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
    margin-top: 20px;
    margin-left: 10px;
  }
  .table-title {
    color: #ffffff;
    text-align: center;
    font-size: 40px;
    padding: 10px;
  }
  
  .table-dark {
    color: white;
  }
  
  .container-background {
    background-color: rgb(26, 26, 26);
    height: 100%;
  }
  
  .container-1 {
    height: 100vh;
  }
  
  .col-table-main {
    height: auto;
    background-color: #000;
  }
  .grant-page-container {
    padding: 3%;
    background-color: #fffcf9 !important;
  }
  
  .banner-background-container {
    text-align: right;
    margin: 3rem;
  }
  .home-button-container {
    text-align: center;
  }
  
  .sticky-contact-button {
    bottom: 0;
    right: 0;
    position: sticky;
    text-align: right;
  }
  .grant-page-container-2 {
    padding: 3%;
    background-color: #fffcf9 !important;
    height: 100vh;
  }
  .login-background {
    background-color: #ffffff;
    height: 100vh !important;
  }
  .filter-search-bar {
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  .data-table-main {
    width: 30px !important;
  }
  
  #td {
    width: auto !important;
  }
  
  .export-csv-button {
    background-color: #7000ff !important;
    color: white !important;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
  }
  .table-header {
    font-size: 2.5rem;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
  }
  .glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
  }

  .CSVText {
    color: white;
  }

  .glow-on-hover1 {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: black;
    background: #ffffff;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
  }
  /* #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000 */
  .glow-on-hover:before {
    content: "";
    background: linear-gradient(
      45deg,
      #deff00,
      #76fc44,
      #00ffd7,
      #7000ff,
      #deff00
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }
  
  .glow-on-hover:active {
    color: #000;
  }
  
  .glow-on-hover:active:after {
    background: transparent;
  }
  
  .glow-on-hover:hover:before {
    opacity: 1;
  }
  
  .glow-on-hover:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
  }
  
  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  .card {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    background: #fffcf9;
    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #6f00ff94;
    border-bottom-color: #7000ff;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: absolute;
    top: 45%;
    left: 50%;
    margin: -0.5rem 0 0 -1.3rem;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .busy {
    position: absolute;
    left: 50%;
    top: 35%;
    display: none;
    z-index: 1000;
    height: 31px;
    width: 31px;
  }
  
  .busy-holder {
    background: transparent;
    width: 100%;
    height: 100%;
  }
  .bg-black {
    background: #000000;
  }
  
  .nl-item {
    color: #ffffff;
    padding: 10px;
  }
  
  .nl-item:hover {
    color: #ffffff;
    padding: 10px;
  }
  
  .nl-item:visited {
    font-weight: bold;
  }
  .csv-button {
    background-color: #7000ff !important;
    color: white !important;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
    border: none !important;
  }
  .user-account-header {
    border-radius: 0px !important;
    padding: 0px;
    background-color: #000000 !important;
    text-align: center;
  }
  
  .account-card {
    padding: 0px !important;
    margin: auto !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .profile-image-header {
    text-align: center;
  }
  
  .profile-card-main {
    background-color: #e6e6e6 !important;
    border-radius: 20px !important;
    border-style: solid !important;
    border-color: #000000 !important;
    border-width: 1px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: auto;
    margin-left: 10%;
  }
  
  .profile-text-header {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .image-cropper {
    max-width: 100%;
    max-height: 100%;
    width: 10rem;
    height: 8rem;
    position: relative;
    overflow: hidden;
    border-radius: 0% 4rem 4rem 0%;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    border-width: 1px;
    border-style: solid;
    display: inline-block;
  }
  
  .user-account-info-text {
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .truck-image {
    display: inline;
    margin: auto;
    height: 100%;
    width: auto;
  }
  
  .user-account-header-text {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #ffffff;
    margin: 0px;
    font-size: medium;
  }
  
  .pointer-color {
    color: #ffffff;
  }
  
  .pointer-stack {
    margin: 0px;
  }
  
  .button-view-account-details {
    background-color: #000000 !important;
    color: #ffffff;
    border-color: #000000 !important;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
  }
  
  .user-account-col-buttons {
    text-align: center;
    padding: 0px !important;
  }
  .login-card {
    width: 300px;
    height: auto;
    background-color: white;
    border-radius: 10px;
    border-style: solid;
    border-color: #000000;
    border-width: 1px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    margin: 10vh auto;
  }
  
  .login-header {
    background-color: #000000;
    color: #ffffff;
    font-size: 1em;
    margin: auto;
    text-transform: uppercase;
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 500;
    font-style: normal;
    padding: 1rem;
  }
  
  .login-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .login-background {
    height: 100vh;
    background-color: #fffcf9 !important;
  
  }
  
  .sso-login {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 0px;
  }
  
  .sso-split {
    margin-top: 10px;
    overflow: hidden;
    text-align: center;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 500;
    font-style: normal;
    color: grey;
  }
  
  .sso-split:before {
    background-color: lightgrey;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
    right: 0.5em;
    margin-left: -50%;
  }
  
  .sso-split:after {
    background-color: lightgrey;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
    left: 0.5em;
    margin-right: -50%;
  }
  .login-button {
    background-color: #000000 !important;
    color: #ffffff;
    border-color: #000000 !important;
    width: 100px !important;
  
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
    margin-top: 20px;
  }
  
  .login-div {
    text-align: center;
  }
  
  .login-button-col {
    align-items: center !important ;
    align-content: center !important;
  }
  
  .input-form-group {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .form-group-text {
    margin-top: 10px;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .account-div {
    margin-top: 20px;
    text-align: center;
  }
  
  .login-card {
    padding-bottom: 40px;
  }
  .logout {
    background-color: grey !important;
    color: #ffffff;
    border-color: grey !important;
    margin-right: auto;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
  }
  .user-account-header {
    border-radius: 0px !important;
    padding: 0px;
    background-color: #000000 !important;
    text-align: center;
  }
  
  .account-card {
    padding: 0px !important;
    margin: auto !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .profile-image-header {
    text-align: center;
  }
  
  .profile-card-main {
    background-color: #e6e6e6 !important;
    border-radius: 20px !important;
    border-style: solid !important;
    border-color: #000000 !important;
    border-width: 1px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .profile-text-header {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .image-cropper {
    width: 10rem;
    height: 8rem;
    position: relative;
    overflow: hidden;
    border-radius: 0% 4rem 4rem 0%;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    border-width: 1px;
    border-style: solid;
    display: inline-block;
  }
  
  .account-button-columns{
      margin: 0px !important;
      padding: 0px !important;
  }
  
  .user-account-info-text{
      font-family: neue-haas-grotesk-text, sans-serif;
      font-weight: 500;
      font-style: normal;
  }
  
  .truck-image {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
  }
  
  .user-account-header-text {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #ffffff;
    margin: 0px;
    font-size: medium;
  }
  
  .pointer-color {
    color: #ffffff;
  }
  
  .pointer-stack {
    margin: 0px;
  }
  
  .button-view-account-details {
    background-color: #000000 !important;
    color: #ffffff;
    border-color: #000000 !important;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
  }
        