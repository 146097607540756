.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #6f00ff94;
  border-bottom-color: #7000ff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -0.5rem 0 0 -1.3rem;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.busy {
  position: absolute;
  left: 50%;
  top: 35%;
  display: none;
  z-index: 1000;
  height: 31px;
  width: 31px;
}

.busy-holder {
  background: transparent;
  width: 100%;
  height: 100%;
}
